class MatrizService {
  constructor() {}
  resources = () => ({
    listarArticulosServicios: {
      uri: `/v1/presupuesto/listar-articulo-servicio`,
      method: ['get']
    },
    exportarArticulosServicios: {
      uri: `/v1/presupuesto/exportar-articulo-servicio`,
      method: ['get']
    },
    exportarModeloArticulosServicios: {
      uri: `/v1/presupuesto/descargar-formato-articulo-servicio`,
      method: ['get']
    },
    importarArticulosServicios: {
      uri: `/v1/presupuesto/importar-articulo-servicio`,
      method: ['post'],
      headers: { 'Content-Type': 'application/json' }
    },
    permisos: {
      uri: `/v1/usuario/permisos`,
      method: ['get']
    }
  });
}

export default MatrizService;
